// Feature card

$featured-card-height: 32.5rem;

.btn {
  &.product__btn-book {
    @extend .btn-secondary;
  }

  &.product__btn-enquire {
    @extend .btn-primary;
  }
}

.card--featured {
  display: block;

  @include media-breakpoint-up(md) {
    border: none;
    background: transparent;

    .card__image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      .picture__wrapper {
        padding-bottom: 0 !important;
        height: $featured-card-height;
      }
    }

    .card__body {
      background-color: $white;
      border: solid 1px $card-border-colour;
      margin-top: $featured-card-height - $card-padding * 5;
      width: 50%;
      padding: $card-padding;
      margin-left: $card-padding;
      position: relative;
      border-radius: 1rem;
      overflow: hidden;
    }

    .card__title {
      @include heading-three;
    }
  }
}

.header__main {
  border-bottom: solid 6px $secondary;
}

.header--shrink {
  .header__main {
    border-bottom-width: 0.25rem;
  }
}

.card,
.simple-tile,
.featured-banner,
.video__wrapper,
.picture__wrapper {
  border-radius: 1rem;
  overflow: hidden;
}
