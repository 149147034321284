// Fonts

$body-font: ("Open Sans", sans-serif) 400;
$bold-font: ("Open Sans", sans-serif) 600;
$heading-font: (ff-cocon-pro, sans-serif) 400;

// Colours
$primary: #1f3d7c; // CCC Navy Blue
$secondary: #980f0c; // CCC Red (darker shade)
$grey: #919191;

// Header
$header-bg-colour: $primary;

// ***********************
// Spacing system
// ***********************
$enable-negative-margins: true;
